<template>
	<v-row>
        <v-col>
            <div class="footer d-flex justify-between items-center">
                <a href="/privacy">Datenschutz</a>
                <a href="/imprint">Impressum</a>
                <a href="/agb">AGB</a>
            </div>
        </v-col>
    </v-row>
</template>



<script>

export default {
	name: 'LandingFooter',
	methods: {

    },
	mounted() {
		
	},
}
</script>

<style lang="sass" scoped>
.footer
	background: white
	border-top: solid 2px black
	padding: 25px
	color: white
	width: 100%
	li
		margin-bottom: 10px
	a
		color: black
		font-family: "grotta-semibold"
		letter-spacing: 0em
		font-size: 1.3em
		margin: 0 25px
	</style>

