<template>
	<div class="wrapper">
		<v-sheet class="backdrop-dots">
			<v-row align="center" class="hero" no-gutters>
				<v-col cols="12" lg="12" class="fill-height right-box d-flex align-center justify-center">
					<v-sheet id="bB" class="box pa-10" elevation="0" color="white" :outlined="true" v-if="loading">
						<div class="d-flex justify-center align-center flex-column">
							<v-progress-circular indeterminate class="mb-5"></v-progress-circular>
							<div class="text-p">Kundendaten wurden geladen, du wirst zum Konfigurator weitergeleitet.</div>
						</div>
					</v-sheet>
				</v-col>
			</v-row>
		</v-sheet>
	</div>
</template>

<script>
export default {
	name: 'RequestAsCustomer',
	computed: {
		customerId() {
			return this.$route.query.customer
		},
	},
	data() {
		return {
			loading: true,
		}
	},
	mounted() {
		if (!this.$route.query.customer || !this.$route.query.token) this.$router.push('/')
		else this.send()
	},
	methods: {
		send() {
			this.loading = true

			// login first with one time token
			this.$store
				.dispatch('Auth/loginWithOneTimeToken', this.$route.query.token)
				.then(() => this.$store.dispatch('ConfiguratorRequest/resetCustomer'))
				.then(() => this.$store.dispatch('Auth/getCustomerDataById', this.customerId))
				.then((res) => this.$store.dispatch('ConfiguratorRequest/setCustomer', res.customer))
				.then(() => this.$store.dispatch('ConfiguratorFiles/fetchCustomerFiles', this.customerId))
				.then(() => this.$store.dispatch('ConfiguratorRequest/setRequestCreatedByAdmin', true))
				.then(() => this.$store.dispatch('Configurator/resetConfiguration'))
				.then(() => {
					this.$router.push('/config')
				})
		},
	},
}
</script>

<style lang="sass" scoped>
.wrapper
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .inner
        background: white
        padding: 40px
</style>
