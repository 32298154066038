<template>
	<div class="loading-wrapper">
		<v-progress-circular indeterminate></v-progress-circular>
		<div class="loading-label">{{ text }}</div>
	</div>
</template>

<script>
export default {
	name: 'BigLoader',
	props: {
		text: { type: String },
	},
}
</script>

<style scoped lang="sass">
.loading-wrapper
    position: fixed
    top: 0px
    bottom: 0px
    left: 0px
    right: 0px
    z-index: 100
    background: white
    // width: 100%
    // height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    .loading-label
        font-weight: 700
        font-style: italic
        padding-top: 20px
        font-size: 2em
</style>