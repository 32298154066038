import { render, staticRenderFns } from "./BigLoader.vue?vue&type=template&id=4584ae7d&scoped=true&"
import script from "./BigLoader.vue?vue&type=script&lang=js&"
export * from "./BigLoader.vue?vue&type=script&lang=js&"
import style0 from "./BigLoader.vue?vue&type=style&index=0&id=4584ae7d&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4584ae7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
