<template>
	<div class="icon-scroll">
		<svg viewBox="0 0 42.5 42.5">
			<path
				d="M21 3C14.4 3 9 8.4 9 15v10c0 6.6 5.4 13 12 13s12-6.4 12-13V15c0-6.6-5.4-12-12-12zm10 21.5C31 30.4 26.5 36 21 36s-10-5.6-10-11.5v-8.9C11 9.8 15.5 5 21 5s10 4.8 10 10.6v8.9z"
				id="Rounded_Rectangle_1_copy"
				class="outline"
			></path>
			<circle cx="21" cy="15" r="2" id="Ellipse_1" class="st0"></circle>
		</svg>
	</div>
</template>



<script>
export default {
	name: 'Scroll',
}
</script>

<style lang="scss" scoped>
.icon-scroll {
	height: 100%;
	width: 100%;
	text-align: center;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: 111;
	bottom: 10px;
}

.icon-scroll svg {
	width: 100px;
	height: 100px;
	margin: auto;
}

.icon-scroll .outline {
	fill: black;
}

.icon-scroll circle {
	fill: black;
	-webkit-animation: iconScroll 5s infinite;
	animation: iconScroll 5s infinite;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

@-webkit-keyframes iconScroll {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	5% {
		-webkit-transform: translateY(9px);
		transform: translateY(9px);
	}

	15% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	20% {
		-webkit-transform: translateY(9px);
		transform: translateY(9px);
	}

	30% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes iconScroll {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	5% {
		-webkit-transform: translateY(9px);
		transform: translateY(9px);
	}

	15% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	20% {
		-webkit-transform: translateY(9px);
		transform: translateY(9px);
	}

	30% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
</style>